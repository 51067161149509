.text-figures--3d4mU {
  width: 200px;
  padding-left: 0px;
  margin: 0px; }
  .text-figures--3d4mU li {
    border-top: 1px solid #bebebe;
    display: block;
    padding: 10px 0px 20px 0px; }
  .text-figures--3d4mU li:last-child {
    padding-bottom: 0px; }

.vertical-chart--1N2dr {
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-bottom-left-radius: 15px;
  width: 40px;
  padding: 5px;
  padding-right: 0px;
  margin-right: 20px;
  display: flex;
  align-items: flex-end; }
  .vertical-chart--1N2dr .chart-value--1pd6b {
    height: 100px;
    background-color: #3af3c4;
    width: 100%;
    border-bottom-left-radius: 10px; }

.status-chart--3JLQg {
  margin-top: 0px; }
