.consumption-component--2TPQK {
  margin-top: 20px; }
  .consumption-component--2TPQK .text-figures--3wzHV {
    padding-left: 0px; }
    .consumption-component--2TPQK .text-figures--3wzHV li {
      border-top: 1px solid #bebebe;
      display: block;
      padding: 10px 0px 20px 0px; }
    .consumption-component--2TPQK .text-figures--3wzHV li {
      width: 100%;
      padding-right: 20px;
      padding-left: 60px;
      display: flex;
      justify-content: space-between; }
    .consumption-component--2TPQK .text-figures--3wzHV li:last-child {
      border-bottom: 1px solid #bebebe; }

.consumption-linechart--3enyC {
  width: 40%;
  margin-left: 20px;
  border-bottom: 2px solid #fff; }
  .consumption-linechart--3enyC img {
    height: 190px;
    width: 100%; }
