/**
 *  Viewport sized typography with minimum and maximum values
 *
 *  @author Eduardo Boucas (@eduardoboucas)
 *
 *  @param {Number}   $responsive  - Viewport-based size
 *  @param {Number}   $min         - Minimum font size (px)
 *  @param {Number}   $max         - Maximum font size (px)
 *                                   (optional)
 *  @param {Number}   $fallback    - Fallback for viewport-
 *                                   based units (optional)
 *
 *  @example scss - 5vw font size (with 50px fallback),
 *                  minimum of 35px and maximum of 150px
 *  @include responsive-font(5vw, 35px, 150px, 50px);
 */
.limit-min--3DdrI {
  font-size: 3vw; }
  @media (max-width: 666.66667px) {
    .limit-min--3DdrI {
      font-size: 20px; } }

.limit-min-max--2Muxu {
  font-size: 3vw; }
  @media (max-width: 666.66667px) {
    .limit-min-max--2Muxu {
      font-size: 20px; } }
  @media (min-width: 1666.66667px) {
    .limit-min-max--2Muxu {
      font-size: 50px; } }

@font-face {
  font-family: 'NLYCS-Bold', 'NLYCS-Medium', 'NLYCS-Regular';
  src: url("/fonts/nlycs.woff") format("woff"); }

/**
 *  Viewport sized typography with minimum and maximum values
 *
 *  @author Eduardo Boucas (@eduardoboucas)
 *
 *  @param {Number}   $responsive  - Viewport-based size
 *  @param {Number}   $min         - Minimum font size (px)
 *  @param {Number}   $max         - Maximum font size (px)
 *                                   (optional)
 *  @param {Number}   $fallback    - Fallback for viewport-
 *                                   based units (optional)
 *
 *  @example scss - 5vw font size (with 50px fallback),
 *                  minimum of 35px and maximum of 150px
 *  @include responsive-font(5vw, 35px, 150px, 50px);
 */
.limit-min--3DdrI {
  font-size: 3vw; }
  @media (max-width: 666.66667px) {
    .limit-min--3DdrI {
      font-size: 20px; } }

.limit-min-max--2Muxu {
  font-size: 3vw; }
  @media (max-width: 666.66667px) {
    .limit-min-max--2Muxu {
      font-size: 20px; } }
  @media (min-width: 1666.66667px) {
    .limit-min-max--2Muxu {
      font-size: 50px; } }

.loadingIndicatorOverlay--cpySh {
  background-color: #fff;
  opacity: 0.9;
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 1000; }
  .loadingIndicatorOverlay--cpySh > div {
    margin-top: 30%;
    text-align: center; }
    .loadingIndicatorOverlay--cpySh > div p::before {
      animation: rotationLoop--1C-l5 3s linear infinite;
      -moz-animation: rotationLoop--1C-l5 3s linear infinite;
      -webkit-animation: rotationLoop--1C-l5 3s linear infinite;
      -o-animation: rotationLoop--1C-l5 3s linear infinite;
      left: -10px; }

@keyframes rotationLoop--1C-l5 {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(180deg); }
  100% {
    transform: rotate(360deg); } }

@-moz-keyframes rotationLoop--1C-l5 {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(180deg); }
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes rotationLoop--1C-l5 {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(180deg); }
  100% {
    transform: rotate(360deg); } }

@-o-keyframes rotationLoop--1C-l5 {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(180deg); }
  100% {
    transform: rotate(360deg); } }
