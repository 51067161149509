/**
 *  Viewport sized typography with minimum and maximum values
 *
 *  @author Eduardo Boucas (@eduardoboucas)
 *
 *  @param {Number}   $responsive  - Viewport-based size
 *  @param {Number}   $min         - Minimum font size (px)
 *  @param {Number}   $max         - Maximum font size (px)
 *                                   (optional)
 *  @param {Number}   $fallback    - Fallback for viewport-
 *                                   based units (optional)
 *
 *  @example scss - 5vw font size (with 50px fallback),
 *                  minimum of 35px and maximum of 150px
 *  @include responsive-font(5vw, 35px, 150px, 50px);
 */
.limit-min--2PDpV {
  font-size: 3vw; }
  @media (max-width: 666.66667px) {
    .limit-min--2PDpV {
      font-size: 20px; } }

.limit-min-max--WK7YA {
  font-size: 3vw; }
  @media (max-width: 666.66667px) {
    .limit-min-max--WK7YA {
      font-size: 20px; } }
  @media (min-width: 1666.66667px) {
    .limit-min-max--WK7YA {
      font-size: 50px; } }

@font-face {
  font-family: 'NLYCS-Bold', 'NLYCS-Medium', 'NLYCS-Regular';
  src: url("/fonts/nlycs.woff") format("woff"); }

* {
  box-sizing: border-box;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  outline: none; }

body,
html {
  font-family: 'NLYCS-Regular', Helvetica, Arial, sans-serif;
  backface-visibility: hidden;
  background: #fff;
  color: #000;
  font-size: 14px;
  margin: 0;
  padding: 0;
  position: relative;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased; }

body {
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'NLYCS-Medium', Helvetica, Arial, sans-serif;
  margin-top: 0;
  word-break: break-word; }

h1 {
  font-family: 'NLYCS-Bold', Helvetica, Arial, sans-serif;
  font-size: 2.1em;
  margin-bottom: 1em; }

h2 {
  font-family: 'NLYCS-Bold', Helvetica, Arial, sans-serif;
  font-size: 1.9em;
  margin-bottom: 0.9em; }

h3 {
  font-size: 1.7em;
  margin-bottom: 0.8em; }

h4 {
  font-size: 1.5em;
  margin-bottom: 0.7em; }

h5 {
  font-family: 'NLYCS-Regular', Helvetica, Arial, sans-serif;
  font-size: 1.3em;
  margin-bottom: 0.6em; }

h6 {
  font-family: 'NLYCS-Regular', Helvetica, Arial, sans-serif;
  font-size: 1.1em;
  margin-bottom: 0.5em; }

a {
  color: #164867;
  cursor: pointer;
  text-decoration: none; }
  a.active--3tdd1, a:hover {
    color: #2474a6; }

.button--1SHIb {
  background-color: #e1e1e1;
  border-color: #bbb;
  display: inline-block;
  border-radius: 3px;
  padding: 5px 10px; }

@media (max-width: 700px) {
  h1 {
    font-size: 1.9em; } }

/*
    @else if $point == large      { @media (max-width: $pageBreakpointLarge)      { @content; }}
    @else if $point == extraLarge { @media (max-width: $pageBreakpointExtraLarge) { @content; }}
    */
.left-standard-padding--3d35D {
  padding-left: 60px; }

.left-standard-margin--3wuNW {
  margin-left: 60px; }

.source-chart--3GToc {
  flex: 1;
  height: 380px;
  margin-right: 50px; }

.source-chart--3GToc svg {
  background-image: url(/e59826ff3c68d6ccb97da36813b9a0bc.png);
  background-size: 210%;
  background-repeat: no-repeat;
  background-position: left 35% center; }

.pice-demo--tFl3y {
  max-height: 100%; }

.source-chart--3GToc .arc-path--1sc6X {
  fill: #50d9cf; }

.source-chart--3GToc .arc-label--1g5X5 {
  fill: #fff;
  font-size: 20px; }

.source-chart--3GToc .arc-lines--2upjR {
  stroke: #50d9cf; }

.charging-source-heading--vYHwX {
  position: absolute;
  top: 32%;
  left: 130px;
  font-size: 24px;
  letter-spacing: 2px; }
